





























import Vue from "vue";
import { API_URL_MODELS } from "@/config";
import ModelCard from "@/components/model/ModelCard.vue";
import Feed from "@/components/general/Feed.vue";
import FilterDropdown from "@/components/general/FilterDropdown.vue";

export default Vue.extend({
  name: "Models",
  components: { Feed, ModelCard, FilterDropdown },
  data() {
    return {
      API_URL_MODELS,
    };
  },
});
