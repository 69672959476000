

























import Vue, { PropType } from "vue";
import type { Model } from "@/types";

export default Vue.extend({
  name: "ModelCard",
  props: {
    model: {
      type: Object as PropType<Model>,
      required: true,
    },
  },
});
